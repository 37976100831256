import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BenefitGlassService {

  private _benefitURL=`${environment.baseUrl}/benefitsGlass`

  constructor(private http: HttpClient) { }

      getAllBenefits():Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/allBenefitsGlass`);
      }    

      getListArchivedBenefits() :Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/listBenefitsGlassArchived`);
      }   

      createBenefitGlass(benefitGlass: any):Observable<Object> {
        return this.http.post(`${this._benefitURL}`,benefitGlass);
      }

      // list benfits-glass by Site
      getBenefitsGlassBysite(idSite : any):Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/listBenefitsGlass-by-site/${idSite}`);
      } 

       //get détails benefit (details is color and name)
    getDetailsBenefitGlass(idBnefit : any):Observable<any>
    {
      return this.http.get<any>(`${this._benefitURL}/details-benefitGlass/${idBnefit}`);
    }  

    //   getBenefitsBysite(idSite : any):Observable<any>
    //   {
    //     return this.http.get<any>(`${this._benefitURL}/listBenefits-by-site/${idSite}`);
    //   } 

        //Occurence à mofifer ca dans le js des occcurences des que il va creer
      getOccurrencesByBenefit(idBenefit : any):Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/listOccurrence-by-benefitGlass/${idBenefit}`);
      } 

      // update occurence
      EditOccurence(id:string,occurence:any):Observable<Object> {
        return this.http.put(`${this._benefitURL}/updateOccurence/${id}`,occurence);
      }


    //   //calendar
    //   getOccurrences_calendar_ByBenefit(idBenefit : any):Observable<any>
    //   {
    //     return this.http.get<any>(`${this._benefitURL}/listOccurrence-calendar-by-benefit/${idBenefit}`);
    //   } 


    //   //// list Sites By subContractor (a travers benefits)
    //   getList_Sites_by_subContractor(idSubContrators : any):Observable<any>
    //   {
    //     return this.http.get<any>(`${this._benefitURL}/list_Sites_by_subContractor/${idSubContrators}`);
    //   } 

    // //// list Sites By subContractor (a travers benefits)
    // getList_Sites_by_Worker(idWorker : any):Observable<any>
    // {
    //   return this.http.get<any>(`${this._benefitURL}/list_Sites_by_worker/${idWorker}`);
    // }  

    // //get détails benefit (details is color and name)
    // getDetailsBenefit(idBnefit : any):Observable<any>
    // {
    //   return this.http.get<any>(`${this._benefitURL}/details-benefit/${idBnefit}`);
    // }  

    // // agenda
    // getOccurrences(){
    //   return this.http.get<any>(`${this._benefitURL}/get/all/occurrences`);
    // }

    // // agenda
    // getWorkersByBenefit(idBnefit : any){
    //   return this.http.get<any>(`${this._benefitURL}/getWorkers/By-benefit/${idBnefit}`);
    // }

    //export xls benefitswithOccrence
    getbenefitsGlassWithOccrences(idSite :any, typeBnefit :any, frequency :any ,startDate :any ,endDate :any, subContractor :any):Observable<any>
    {
      return this.http.get<any>(`${this._benefitURL}/list-benfitsGlass-Occurrences-XLS/${idSite}/${typeBnefit}/${frequency}/${startDate}/${endDate}/${subContractor}`);
    } 


       //edit benefit
       editDataBenefitGlass(idBnefit,benefit){
        return this.http.put<any>(`${this._benefitURL}/${idBnefit}`,benefit);   
       }

    


}
