import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { BenefitGlassService } from 'src/app/_services/benefit-glass.service';
import { SharedService } from 'src/app/_services/shared.service';
import { TypebenefitGlassService } from 'src/app/_services/typeBenefitGlass';
@Component({
  selector: 'app-list-glass-archived',
  templateUrl: './list-glass-archived.component.html',
  styleUrls: ['./list-glass-archived.component.css','../../../../../_shared/list.css']
})
export class ListGlassArchivedComponent implements OnInit {

  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  col5 = false
  col6 = false

  copyListPrestation;
  ListFrequence:any;
  ListChantier:any;
  ListSubContractor :any
  nomToFiltred:any;
  frequenceToFiltred:any;
  ddToFiltred:any;
  dfToFiltred:any;
  chantierToFiltred:any;
  sub_contratorToFiltred: any;
  typesBenefit :any

  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  benefits


  constructor( private router: Router ,private sharedService: SharedService ,private benefitGlassService : BenefitGlassService,
    private spinnerService: NgxSpinnerService, public iziToast: Ng2IzitoastService,private typeBenefitGlassService :TypebenefitGlassService ) { 
    
    
  }

  ngOnInit(): void {
    // this.sort(this.table[0].column1)
    this.getBenefitsArchiveds()
    this.getTypes()
    
    
  }
  getBenefitsArchiveds() {
    this.benefitGlassService.getListArchivedBenefits().subscribe((e) => {
      this.benefits =e 
      this.copyListPrestation = this.benefits;
      this.ListChantier = Array.from(new Set(this.benefits.map(item => item.site)));
      this.ListFrequence =   ["Hebdomadaire","Mensuelle","Trimestrielle 1-4-7-10","Trimestrielle 2-5-8-11","Trimestrielle 3-6-9-12",
      "Quadrimestrielle","Semestrielle","Annuelle","Bi-annuelle"]
      this.ListSubContractor =   Array.from(new Set(this.benefits.map(item => item.subContractor)))
    });
  }

  getTypes() {
    this.typeBenefitGlassService.getTypesBenefitsGlass().subscribe((e) => {
      this.typesBenefit = e
      this.typesBenefit =this.typesBenefit.map(item => item.type);
    });
  }


  details(id){
    const url = `admin/chantiers/vitres/details/${id}`;
    window.open(url, '_blank');
  }
  
  onTableDataChange(event: any) {
    this.page = event;
    this.benefits = this.benefits
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getBenefitsArchiveds()
  }

  setVariableArchive(){
    this.sharedService.setVariable("archive");
  }

  setVariableNotArchive(){
    this.sharedService.setVariable("NotArchive");
  }


  gotoListArchivedSites() //gotoListArchivedSites
  {
    this.router.navigate(['/admin/chantiers' ])
    this.setVariableArchive()
  }

  gotoListSites() //gotoListArchivedSites
  {
    this.router.navigate(['/admin/chantiers' ])
    this.setVariableNotArchive()
  }

  sort(column,descend,col)
  {
   
  
  switch (column) {
   
   case "site":
     if(this.col1 == false){
     this.col1 = true
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = false
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "type":  
   if(this.col2 == false){
     this.col1 = false
     this.col2 = true
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = false
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "frequence": 
     if(this.col3 == false){  
     this.col1 = false
     this.col2 = false
     this.col3 = true
     this.col4 = false
     this.col5 = false
     this.col6 = false  
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "startDate":   
   if(this.col4 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = true
     this.col5 = false
     this.col6 = false  
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "endDate":  
   if(this.col5 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = true
     this.col6 = false
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
     case "subContractor":  
   if(this.col6 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = true
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   default :
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false   
       break   
  
  }
  
  //  if(column == "column6"){
  //  if(descend == false){
  //  this.criteria = {
  //    property: column ,
  //    descending: descend
  //  };
  //  this.descend = true
  //  }
  //  else{
  //    this.criteria = {
  //      property: column ,
  //      descending: descend
  //    };
  //    this.descend = false
  //  }
  // }
  // else
  // {
  
     if(descend == false){
     this.criteria = {
       property: column ,
       descending: true
     };
     this.descend = true
     }
     else{
       this.criteria = {
         property: column ,
         descending: false
       };
       this.descend = false
     }
  
  }
  filter() {
    this.benefits = this.copyListPrestation;
    let filteredsListBenefits = [...this.copyListPrestation];
    const FrequenceFilterLower = this.frequenceToFiltred ? this.frequenceToFiltred.toLowerCase() : '';
    const chantierFilterLower = this.chantierToFiltred ? this.chantierToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const dateDebutFilterLower = this.ddToFiltred ? this.ddToFiltred.toLowerCase() : '';
    const dateFinFilterLower = this.dfToFiltred ? this.dfToFiltred.toLowerCase() : '';
    const sub_contratordFilterLower = this.sub_contratorToFiltred ? this.sub_contratorToFiltred.toLowerCase() : '';
  if(dateDebutFilterLower && dateDebutFilterLower != '' && dateFinFilterLower && dateFinFilterLower !=''){
    filteredsListBenefits = this.benefits.filter(item => {
      const chantierFilter = chantierFilterLower ? item.site.toLowerCase() === chantierFilterLower : true;
      const frequenceFilter = FrequenceFilterLower ? item.frequence.toLowerCase() === FrequenceFilterLower : true;
  
      // Case-insensitive name search for a single character
      const nomFilter = nomFilterLower ? item.type.toLowerCase() === nomFilterLower : true;
      const ddFilter = dateDebutFilterLower != '' ? (item.recc && item.recc.length > 0 && item.recc.some(x=>new Date(x.date)>= new Date(dateDebutFilterLower) && new Date(dateFinFilterLower) >=new Date(x.date)))  : true;
     
     
        const dfFilter = true;
      
      const subContractorFilter = sub_contratordFilterLower ? item.subContractor.toLowerCase().includes(sub_contratordFilterLower) : true;
  
      return nomFilter && ddFilter && dfFilter && subContractorFilter && chantierFilter && frequenceFilter ;
      
    });
  }else{
    filteredsListBenefits = this.benefits.filter(item => {
      const chantierFilter = chantierFilterLower ? item.site.toLowerCase() === chantierFilterLower : true;
      const frequenceFilter = FrequenceFilterLower ? item.frequence.toLowerCase() === FrequenceFilterLower : true;
  
      // Case-insensitive name search for a single character
      const nomFilter = nomFilterLower ? item.type.toLowerCase() === nomFilterLower : true;
      const ddFilter = dateDebutFilterLower != '' ? (item.recc && item.recc.length > 0 && item.recc.some(x=>new Date(x.date)>= new Date(dateDebutFilterLower)))  : true;
     
     
        const dfFilter = dateFinFilterLower != '' ? (item.recc && item.recc.length > 0 && item.recc.some(x=>new Date(x.date)<= new Date(dateFinFilterLower)))  : true;
      
      const subContractorFilter = sub_contratordFilterLower ? item.subContractor.toLowerCase().includes(sub_contratordFilterLower) : true;
  
      return nomFilter && ddFilter && dfFilter && subContractorFilter && chantierFilter && frequenceFilter ;
      
    });
  }
    
    this.benefits = filteredsListBenefits;
  
    // Reset pagination
    this.page = 1;
  }
  resetFilters() {
    this.nomToFiltred = undefined 
    this.ddToFiltred = undefined
    this.dfToFiltred = undefined
    this.chantierToFiltred = undefined; 
    this.frequenceToFiltred = undefined;
    this.sub_contratorToFiltred= undefined;
    
    this.filter();
  }

  verifStartDate(date){
    if(!date){
      this.ddToFiltred = undefined
    }
  }

  verifEndDate(date){
    if(!date){
      this.dfToFiltred = undefined
    }
  } 
  goToListTypes(){
    this.router.navigate(["admin/chantiers/vitres/types"]);
  }

  goToListGlass(){
    this.router.navigate(["admin/chantiers/vitres"]);
  }

}
