import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { BenefitGlassService } from 'src/app/_services/benefit-glass.service';
import { SharedService } from 'src/app/_services/shared.service';
import { TypebenefitGlassService } from 'src/app/_services/typeBenefitGlass';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-list-glass',
  templateUrl: './list-glass.component.html',
  styleUrls: ['./list-glass.component.css','../../../../_shared/list.css',]
})
export class ListGlassComponent implements OnInit {


  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  col5 = false
  col6 = false

  copyListPrestation;
  ListFrequence:any;
  ListChantier:any;
  ListSubContractor :any
  nomToFiltred:any;
  frequenceToFiltred:any;
  ddToFiltred:any;
  dfToFiltred:any;
  chantierToFiltred:any;
  sub_contratorToFiltred: any;
  typesBenefit :any

  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  benefits


  constructor( private router: Router ,private sharedService: SharedService ,private benefitGlassService : BenefitGlassService,
    private spinnerService: NgxSpinnerService, public iziToast: Ng2IzitoastService,private typeBenefitGlassService :TypebenefitGlassService ) { 
    
    
  }

  ngOnInit(): void {
    // this.sort(this.table[0].column1)
    this.getBenefits()
    this.getTypes()
    localStorage.removeItem("lastPageContactVisited")
    localStorage.removeItem("lastPageClientVisited")
    localStorage.removeItem("lastPageBenefitVisited")
    localStorage.removeItem("lastPageDocumentVisited")
    localStorage.removeItem("lastPageEtablissementVisited")
    localStorage.removeItem("lastPageSubContractorVisited")
    localStorage.removeItem("lastPageSiteVisited")
    localStorage.removeItem("lastPageWorkerVisited")
    localStorage.removeItem("lastPageSuppafacVisited")

    

    if(localStorage.getItem("lastPageGlassVisited") && 
    localStorage.getItem("lastPageGlassVisited") != undefined && 
    localStorage.getItem("lastPageGlassVisited") != "")
    this.page = Number(localStorage.getItem("lastPageGlassVisited"))   
    
  }
  getBenefits() {
    this.benefitGlassService.getAllBenefits().subscribe((e) => {
      this.benefits =e 
      this.copyListPrestation = this.benefits;
      this.ListChantier = Array.from(new Set(this.benefits.map(item => item.site)));
      this.ListFrequence =   ["Hebdomadaire","Mensuelle","Trimestrielle 1-4-7-10","Trimestrielle 2-5-8-11","Trimestrielle 3-6-9-12",
      "Quadrimestrielle","Semestrielle","Annuelle","Bi-annuelle"]
      this.ListSubContractor =   Array.from(new Set(this.benefits.map(item => item.subContractor)))
    });
  }

  getTypes() {
    this.typeBenefitGlassService.getTypesBenefitsGlass().subscribe((e) => {
      this.typesBenefit = e
      this.typesBenefit =this.typesBenefit.map(item => item.type);
    });
  }
  create() {
    this.router.navigate(["admin/chantiers/vitres/ajout"]);
  }

  details(id){
    const url = `admin/chantiers/vitres/details/${id}`;
    window.open(url, '_blank');
  }
  
  onTableDataChange(event: any) {
    this.page = event;
    this.benefits = this.benefits
    localStorage.setItem("lastPageGlassVisited",this.page.toString())
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getBenefits()
  }

  setVariableArchive(){
    this.sharedService.setVariable("archive");
  }

  setVariableNotArchive(){
    this.sharedService.setVariable("NotArchive");
  }


  gotoListArchivedSites() //gotoListArchivedSites
  {
    this.router.navigate(['/admin/chantiers' ])
    this.setVariableArchive()
  }

  gotoListSites() //gotoListArchivedSites
  {
    this.router.navigate(['/admin/chantiers' ])
    this.setVariableNotArchive()
  }

  sort(column,descend,col)
  {
   
  
  switch (column) {
   
   case "site":
     if(this.col1 == false){
     this.col1 = true
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = false
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "type":  
   if(this.col2 == false){
     this.col1 = false
     this.col2 = true
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = false
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "frequence": 
     if(this.col3 == false){  
     this.col1 = false
     this.col2 = false
     this.col3 = true
     this.col4 = false
     this.col5 = false
     this.col6 = false  
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "startDate":   
   if(this.col4 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = true
     this.col5 = false
     this.col6 = false  
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "endDate":  
   if(this.col5 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = true
     this.col6 = false
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
     case "subContractor":  
   if(this.col6 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = true
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   default :
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false   
       break   
  
  }
  
  //  if(column == "column6"){
  //  if(descend == false){
  //  this.criteria = {
  //    property: column ,
  //    descending: descend
  //  };
  //  this.descend = true
  //  }
  //  else{
  //    this.criteria = {
  //      property: column ,
  //      descending: descend
  //    };
  //    this.descend = false
  //  }
  // }
  // else
  // {
  
     if(descend == false){
     this.criteria = {
       property: column ,
       descending: true
     };
     this.descend = true
     }
     else{
       this.criteria = {
         property: column ,
         descending: false
       };
       this.descend = false
     }
  
  }
  filter() {
    this.benefits = this.copyListPrestation;
    let filteredsListBenefits = [...this.copyListPrestation];
    const FrequenceFilterLower = this.frequenceToFiltred ? this.frequenceToFiltred.toLowerCase() : '';
    const chantierFilterLower = this.chantierToFiltred ? this.chantierToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const dateDebutFilterLower = this.ddToFiltred ? this.ddToFiltred.toLowerCase() : '';
    const dateFinFilterLower = this.dfToFiltred ? this.dfToFiltred.toLowerCase() : '';
    const sub_contratordFilterLower = this.sub_contratorToFiltred ? this.sub_contratorToFiltred.toLowerCase() : '';
  if(dateDebutFilterLower && dateDebutFilterLower != '' && dateFinFilterLower && dateFinFilterLower !=''){
    filteredsListBenefits = this.benefits.filter(item => {
      const chantierFilter = chantierFilterLower ? item.site.toLowerCase() === chantierFilterLower : true;
      const frequenceFilter = FrequenceFilterLower ? item.frequence.toLowerCase() === FrequenceFilterLower : true;
  
      // Case-insensitive name search for a single character
      const nomFilter = nomFilterLower ? item.type.toLowerCase() === nomFilterLower : true;
      const ddFilter = dateDebutFilterLower != '' ? (item.recc && item.recc.length > 0 && item.recc.some(x=>new Date(x.date)>= new Date(dateDebutFilterLower) && new Date(dateFinFilterLower) >=new Date(x.date)))  : true;
     
     
        const dfFilter = true;
      
      const subContractorFilter = sub_contratordFilterLower ? item.subContractor.toLowerCase().includes(sub_contratordFilterLower) : true;
  
      return nomFilter && ddFilter && dfFilter && subContractorFilter && chantierFilter && frequenceFilter ;
      
    });
  }else{
    filteredsListBenefits = this.benefits.filter(item => {
      const chantierFilter = chantierFilterLower ? item.site.toLowerCase() === chantierFilterLower : true;
      const frequenceFilter = FrequenceFilterLower ? item.frequence.toLowerCase() === FrequenceFilterLower : true;
  
      // Case-insensitive name search for a single character
      const nomFilter = nomFilterLower ? item.type.toLowerCase() === nomFilterLower : true;
      const ddFilter = dateDebutFilterLower != '' ? (item.recc && item.recc.length > 0 && item.recc.some(x=>new Date(x.date)>= new Date(dateDebutFilterLower)))  : true;
     
     
        const dfFilter = dateFinFilterLower != '' ? (item.recc && item.recc.length > 0 && item.recc.some(x=>new Date(x.date)<= new Date(dateFinFilterLower)))  : true;
      
      const subContractorFilter = sub_contratordFilterLower ? item.subContractor.toLowerCase().includes(sub_contratordFilterLower) : true;
  
      return nomFilter && ddFilter && dfFilter && subContractorFilter && chantierFilter && frequenceFilter ;
      
    });
  }
    
    this.benefits = filteredsListBenefits;
  
    // Reset pagination
    this.page = 1;
  }
  resetFilters() {
    this.nomToFiltred = undefined 
    this.ddToFiltred = undefined
    this.dfToFiltred = undefined
    this.chantierToFiltred = undefined; 
    this.frequenceToFiltred = undefined;
    this.sub_contratorToFiltred= undefined;
    
    this.filter();
  }
  filterByChantier(event) {
    this.chantierToFiltred = event
  }
  filterByFrequence(event) {
    this.frequenceToFiltred = event
  }
  filterBySubContractor(event) {
    this.sub_contratorToFiltred = event
  }

  exportToExcel(benefits: any[]): void {
    let beefitsResults;
    let siteFiltredSendedToBack = undefined;
    let subContractorFiltredSendedToBack = undefined;
  
    if (this.chantierToFiltred) {
      siteFiltredSendedToBack = benefits[0].id_site;
    }
    if (this.sub_contratorToFiltred) {
      subContractorFiltredSendedToBack = benefits[0].id_subContractor;
    }
    if(!this.ddToFiltred){
      this.ddToFiltred = undefined
    }
    if(!this.dfToFiltred){
      this.dfToFiltred = undefined
    }
  
    this.benefitGlassService.getbenefitsGlassWithOccrences(
      siteFiltredSendedToBack,
      this.nomToFiltred,
      this.frequenceToFiltred,
      this.ddToFiltred,
      this.dfToFiltred,
      subContractorFiltredSendedToBack
    ).subscribe((e) => {
      beefitsResults = e;
      const idBenefitSet = new Set(this.benefits.map(item => item._id));
  
      // Filtering the first list based on idBenefitSet
      const filteredList = beefitsResults.filter(item => idBenefitSet.has(item.idBenfit));

     // const filteredList = beefitsResults
      // Resulting filtered list
      const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
      const data = filteredList.map(benefit => {
        const formattedDate = new Date(benefit.date).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        return {
          'CHANTIER': benefit.site,
          'TYPE DE PRESTATION': benefit.typeBenfit,
          'FRÉQUENCE': benefit.frequence,
          'DATE': formattedDate,
          'PRESTATAIRE': benefit.subContractor,
        };
      });
      this.spinnerService.show();
  
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const columnWidths = [
        { wpx: 120 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 150 },
        { wpx: 150 },
      ];
  
      ws['!cols'] = columnWidths;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Prestations Vitres');
      const filename = `${currentDate}_prestations_vitres.xls`;
  
      setTimeout(() => {
        XLSX.writeFile(wb, filename);
        this.spinnerService.hide(); // Masquer le spinner après l'exportation
      });
    },
    // (error)=>{
    //  let msg :"Une erreur est survenue lors de l'export"
    //  this.iziToast.show({
    //   message: "Une erreur est survenue lors de l'export.",
    //   messageColor: '#800f2f',
    //   titleColor: '#800f2f',
    //   progressBarColor: '#c9184a',
    //   icon: 'bi-exclamation-diamond',
    //   imageWidth: 45,
    //   position: 'topRight',
    //   timeout: 5000,
    //   backgroundColor: '#ff8fa3',
    //   transitionIn: 'flipInX',
    //   transitionOut: 'flipOutX',
    //   overlay: true,
    //   overlayClose: true,
    // });
    // return;
    // }
    );
  }
  verifStartDate(date){
    if(!date){
      this.ddToFiltred = undefined
    }
  }

  verifEndDate(date){
    if(!date){
      this.dfToFiltred = undefined
    }
  }
  update(id) {
    this.router.navigate(["admin/chantiers/vitres/modification/", id]);
  } 
  
  goToListTypes(){
    this.router.navigate(["admin/chantiers/vitres/types"]);
  }

  goToListGlassArchiveds(){
    this.router.navigate(["admin/chantiers/vitres/archives"]);
  }
}


  

  

