import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { BenefitService } from 'src/app/_services/benefit.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SiteService } from 'src/app/_services/site.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { PopupAddTypeBenefitComponent } from '../../popupAdd/popup-add-type-benefit/popup-add-type-benefit.component';
import { TypebenefitService } from 'src/app/_services/typebenefit.service';


@Component({
  selector: 'app-add-benefit',
  templateUrl: './add-benefit.component.html',
  styleUrls: ['./add-benefit.component.css','../../../../_shared/add_update.css']
})
export class AddBenefitComponent implements OnInit {

//   typesBenefit = ["Alus ascenseurs","Avaloirs","Cafétéria","Cage d’escaliers","Caves/locaux techniques","Consommables","Cour intérieure","Démoussage",
//  "Dépoussiérage en hauteur","Douches","Escalier de secours","Extracteurs/grilles","Local poubelles","Local vélos","Luminaires",
// "Matériel IT","Nettoyage","Parking autolaveuse","Parking balayage","Portes","Portes de garage","Sanitaires","Stores","Terrasses","Toiture",
// "Vacant","Ventelles métalliques","Volets extérieurs","Autre"]
typesBenefit

  listMonthsannual =["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]

  listMonthsannual_Bi_annual = []

  datesChooses = []
  monthChooses = []
  sites :any
  hebdates:any
  
  /*weekly*/
  dayToNumber = {
    Monday: "01",
    Tuesday: "02",
    Wednesday: "03",
    Thursday: "04",
    Friday: "05",
    saturday:"06",
    sunday:"07"
  };
  /*weekly*/
  d: string;
  d1: string;
  dateAuj = new Date();
  registerForm: FormGroup;
  submitted = false;
  frequences =["Quotidienne","Hebdomadaire","Tous les 15 jours","Mensuelle","Mois pairs","Mois impairs",
    "Trimestrielle 1-4-7-10","Trimestrielle 2-5-8-11","Trimestrielle 3-6-9-12","Quadrimestrielle","Semestrielle","Bi-annuelle","Annuelle"]
  frequenceSelected :any =""
  selectedColor = '#0e309f';
  billingFrequency =["12","41","46"]
  statuts = ["En cours","Terminé","One shot"]
  listSubContractors :any
  listDates =[]
  idSite
  site
  
  validWeeklyBenefitForm = true
  verifDiffrence3years = true
  workers: any;
  MonthSelectedforFrequenceAnual: number;

  selectedMonthsBiAnnual : string[] = [];
  MonthSelectedforFrequenceBi_annual :any
  objetsAvecIds :any 
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private benefitService :BenefitService,
    private sharedService :SharedService,
    private subContractorService :SubContractorService,
    private siteService :SiteService,
    public iziToast: Ng2IzitoastService,
    private modalService: NgbModal,
    private typeBenefitService :TypebenefitService

  ) { }


  ngOnInit() {
     this.idSite = this.sharedService.getIdSiteSended();
    if(this.idSite){
      this.siteService.getSiteById(this.idSite).subscribe(data => {
        this.site = data;
        this.sharedService.setIdSiteSendTOBenefit("");
     })
    }
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      startDate:['', Validators.required],
      endDate : ['', ],
      frequence : ['', Validators.required],
      listMonths : ['', ],
      listDates : ['', ],
      note: ['', Validators.maxLength(200)], 
      statut : ['', ],
      site : [ this.site, Validators.required],
      color : ['', ], // ignorer required
      subContractor :[, Validators.required],
      contratSubContracor :[false,],
      worker :[],
      duration :[''],
      SubContractorswithWorkers :[]
        },);


  
  this.registerForm.get('frequence').valueChanges.subscribe((option: string) => {
    this.updateFormControls(option);
  });
    // Écouter les changements dans le champ "Sous-traitant"
    this.registerForm.get('subContractor').valueChanges.subscribe(() => {
      // Réinitialiser le champ "Ouvrier(s)" lorsque le "Sous-traitant" change
      this.registerForm.get('worker').setValue(null);
    });
  this.d1 = this.dateAuj.toISOString().substr(0, 10);
  this.getSubContratcors()
  this.getSites()
  this.getTypesBenefits()




}
getSites() {
  this.siteService.getSites_not_Stopedt().subscribe((e) => {
    this.sites = e;
 
  });
}
// list all SubContractors not archived 
getSubContratcors()
{
  this.subContractorService.getList_subContractor().subscribe(
    (data) => {
        this.listSubContractors = data
        })
}

getWorkersBySubContractor(idSubContractor)
{
  this.subContractorService.getWorkersBySub_contractor(idSubContractor).subscribe(
    (data) => {
        this.workers = data
        })
}

getTypesBenefits(){
  
  this.typeBenefitService.getTypesBenefits().subscribe(
    (data) => {
        this.typesBenefit = data
        })
}

onColorChange(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  this.selectedColor = inputElement.value;
}

private updateFormControls(option: string): void {
  if (option === 'Annuelle') {
    this.registerForm.addControl('listOfMonths', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonthsBiAnnual');
  
  }
  else if (option === 'Bi-annuelle') {
    this.registerForm.addControl('listOfMonthsBiAnnual', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonths');
  
  }
}
get f() { return this.registerForm.controls; }

onSubmit() {
 
    this.submitted = true;
    if (this.registerForm.invalid) {     
        return;
    }
    let currentDate = new Date(this.registerForm.value.startDate);
    currentDate.setFullYear(currentDate.getFullYear() + 2);
    var endDate = currentDate.toISOString().slice(0, 10);
    this.registerForm.value.endDate = endDate 

    let parts = this.registerForm.value.startDate.split('-');
    let month = parseInt(parts[1]);
    let dayOfMotnth = parseInt(parts[2]); 

    if(this.registerForm.value.frequence == "Mensuelle"){
      let day = parseInt(parts[2]).toString();
      if(day.length == 1 ){
         day ="0"+day
      }
      this.datesChooses.shift();
      this.datesChooses.push(day)
      }
      if(this.registerForm.value.frequence == "Tous les 15 jours"){
        let day = parseInt(parts[2]).toString();
        let month =parseInt(parts[1])
        if(day.length == 1 ){
           day ="0"+day
        }
        this.datesChooses.shift();
        this.datesChooses.push(day)
        this.monthChooses.shift();
        this.monthChooses.push(month)
        this.registerForm.value.listMonths = this.monthChooses 
        }
  
    let searchWord = "Trimestrielle"
    if ( (this.registerForm.value.frequence.indexOf(searchWord) !== -1)){   
      if(month == 13){
        month = 1
      }
      this.monthChooses.push(month);
      for (let i = 1; i < 4; i++) {
        month += 3;
        if (month > 12) {
          month = month - 12;
        }
        this.monthChooses.push(month);
        this.datesChooses =["01","01","01","01"] 
       }
       this.registerForm.value.listMonths = this.monthChooses 
      }

      if ( (this.registerForm.value.frequence== "Mois pairs")){   
        if(month %2 !=0)
          month=month+1
        if(month == 13){
          month = 1
        }
        this.monthChooses.push(month);
        for (let i = 1; i < 6; i++) {
          month += 2;
          if (month > 12) {
            month = month - 12;
          }
          this.monthChooses.push(month);
          this.datesChooses =["01","01","01","01","01","01"] 
         }
         this.registerForm.value.listMonths = this.monthChooses 
        }
      
      if ( (this.registerForm.value.frequence== "Mois impairs")){   
          if(month %2 ==0)
            month=month+1
          if(month == 13){
            month = 1
          }
          this.monthChooses.push(month);
          for (let i = 1; i < 6; i++) {
            month += 2;
            if (month > 12) {
              month = month - 12;
            }
            this.monthChooses.push(month);
            this.datesChooses =["01","01","01","01","01","01"] 
           }
           this.registerForm.value.listMonths = this.monthChooses 
          }  

      if(this.registerForm.value.frequence == "Quadrimestrielle"){
        //month = month + 1
        // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
        //   month = month - 1
        // }
        if(month == 13){
          month = 1
        }
         
        
        this.monthChooses.push(month);
        for (let i = 1; i < 3; i++) {
          month += 4;
          if (month > 12) {
            month = month - 12;
        }
          this.monthChooses.push(month);
          this.datesChooses =["01","01","01"] 
      }
      this.registerForm.value.listMonths = this.monthChooses 
    }


    if(this.registerForm.value.frequence == "Hebdomadaire"){
      if(this.hebdates == undefined || this.hebdates.length == 0){
        this.validWeeklyBenefitForm = false
        return;
      }
    }


    if(this.registerForm.value.frequence == "Hebdomadaire"){
      this.datesChooses = this.hebdates
    }

    if(this.registerForm.value.frequence == "Quotidienne"){
      this.datesChooses = ["01","02","03","04","05"]
    }

    if(this.registerForm.value.frequence == "Semestrielle"){
      //month = month + 1
      // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
      //   month = month - 1
      // }
      if(month == 13){
        month = 1
      }
      this.monthChooses.push(month);

      for (let i = 1; i < 2; i++) {
        month += 6;
        if (month > 12) {
          month = month - 12;
      }
        this.monthChooses.push(month);
        this.datesChooses =["01","01"] 
      }
      this.registerForm.value.listMonths = this.monthChooses 
      }

      if(this.registerForm.value.frequence == "Annuelle"){
        this.monthChooses.push(this.MonthSelectedforFrequenceAnual)
        this.registerForm.value.listMonths = this.monthChooses
        this.datesChooses =["01"]  
      }

      if(this.registerForm.value.frequence == "Bi-annuelle"){
        if(this.MonthSelectedforFrequenceBi_annual.length == 2)
        {
          this.registerForm.value.listMonths = this.MonthSelectedforFrequenceBi_annual
          this.datesChooses =["01","01"]   
        }
        else{
          this.iziToast.show({
            message:"Merci de choisir deux mois pour la fréquence Bi-annuelle.",
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',	
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            //overlay:true,
            overlayClose:true,	
           });
          return false
        }
      }

    if(this.site){
    this.registerForm.value.site = this.site._id
    }

    this.registerForm.value.listDates = this.datesChooses 
   let convertedlistMonths =[]
   //if(this.registerForm.value.frequence != "Annuelle"){
  for(let month of this.registerForm.value.listMonths){
    if(month < 10){
      month = "0"+month
      convertedlistMonths.push(month)
    }
    else{
      convertedlistMonths.push(month.toString())
    }
  }
    

  // }  
  //  else{
  //   convertedlistMonths = this.monthChooses
  //  }
  this.registerForm.value.listMonths = convertedlistMonths
  for(let obj of this.objetsAvecIds){
    const testList = obj.ids_workers.map(item => item._id);
    obj.ids_workers = testList
      
   }
    this.registerForm.value.SubContractorswithWorkers =  this.objetsAvecIds
    this.benefitService.createBenefit(this.registerForm.value).subscribe(
      (data) => {
        this.router.navigate(['/admin/chantiers/details/'+this.registerForm.value.site ])
        this.sharedService.setIdSiteSendTOBenefit("");
           this.iziToast.show({
            message: "Ajout avec succès",
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          }
          )
}
getFreguence(frequence ) {
  this.frequenceSelected = frequence;
      }

      //Hebdomadaire 
chooseDate(value){
  let index = this.listDates.indexOf(value)
  if(index == -1){
  this.listDates.push(value)
  }
  else{
  this.listDates.splice(index,1)
  }
  const numbersList = this.listDates.map(day => this.dayToNumber[day]);
  this.hebdates=numbersList
  if(this.hebdates.length > 0){
    this.validWeeklyBenefitForm = true
  }
}

getSelectedMonth(value)
{
 // this.selectedMonth =value
  const monthIndex = this.listMonthsannual.indexOf(value)+1;
   this.MonthSelectedforFrequenceAnual = monthIndex
  //this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

getSelectedMonthBiAnnual(event: any) {
  this.selectedMonthsBiAnnual = event;
  let monthIndex1
  let monthIndex2
  let listMonths =[]
  if(this.selectedMonthsBiAnnual[0]){
  monthIndex1  = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[0]['name']);
  listMonths.push(monthIndex1+1)
  }
  if(this.selectedMonthsBiAnnual[1]){
   monthIndex2 = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[1]['name']);
   listMonths.push(monthIndex2+1)
  }
   this.MonthSelectedforFrequenceBi_annual = listMonths
}

gemothsBiAnnual() {
  this.listMonthsannual_Bi_annual = [
    { name: 'Janvier', disabled: false },
    { name: 'Février', disabled: false },
    { name: 'Mars', disabled: false },
    { name: 'Avril', disabled: false },
    { name: 'Mai', disabled: false },
    { name: 'Juin', disabled: false },
    { name: 'Juillet', disabled: false },
    { name: 'Août', disabled: false },
    { name: 'Septembre', disabled: false },
    { name: 'Octobre', disabled: false },
    { name: 'Novembre', disabled: false },
    { name: 'Décembre', disabled: false },
  ];

  if (this.selectedMonthsBiAnnual.length >= 2) {
    const selectedMonthNames = this.selectedMonthsBiAnnual.map((month) => month['name']);

    this.listMonthsannual_Bi_annual = this.listMonthsannual_Bi_annual.map((month) => {
      if (!selectedMonthNames.includes(month.name)) {
        return { ...month, disabled: true };
      }
      return month;
    });
  }
}

cancel(){
  if(this.site){
  this.router.navigate(["admin/chantiers/details", this.site._id]);
}
  else{
    this.router.navigate(['/admin/prestations' ])
  }
}

addType(){
  let modalRef
  modalRef =this.modalService.open(PopupAddTypeBenefitComponent);
}


createListSubcontractors() {
  // Vérifier si this.objetsAvecIds est défini et est un tableau
  if (!Array.isArray(this.objetsAvecIds)) {
    this.objetsAvecIds = [];  // Initialiser en tant que tableau vide si nécessaire
  }
  let identifiants = this.registerForm.value.subContractor;
  for (let i = 0; i < identifiants.length; i++) {
    let existingSubContractor = this.objetsAvecIds.find(obj => obj.id_subcontractor === identifiants[i]);
    if (!existingSubContractor) {
      let monObjet = {
        id_subcontractor: identifiants[i],
        ids_workers: []  // Liste des ouvriers initialement vide
      };
      this.objetsAvecIds.push(monObjet);
    }
  }

}

createListWorkersBySubContractors(i,worker){
  this.objetsAvecIds[i].ids_workers = worker

}

onRemove(idSucontractor){
  this.objetsAvecIds = this.objetsAvecIds.filter(x=>x.id_subcontractor != idSucontractor)
}

// pour recu^pere companyName pour chaque prestatires choisi
getSubContractorName(subId: string): string {
  const subContractor = this.listSubContractors.find((sub) => sub._id === subId);
  return subContractor ? subContractor.companyName : 'Prestataire inconnu';
}

  }
