<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item">
        <a class="color-blue" style="cursor: default"
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li class="breadcrumb-item color-blue">
        <a class="color-blue" routerLink="../../">Chantiers</a>
      </li>
      <li
        *ngIf="site"
        class="breadcrumb-item active color-blue"
        style="cursor: default"
      >
        {{ site.name }}
      </li>
    </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx">
      <div class="row align-items-center">
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</header>
<br />
<!-- Student Profile -->
<div class="student-profile py-4">
  <div *ngIf="site" class="container">
    <div class="col-sm-11 col-12 mb-4 mb-sm-0">
      <!-- Title -->
      <h1 class="h2 mb-0 ls-tight color-blue title-1" style="color: #62b9cb">
        {{ site.name }}
      </h1>
      <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails d'un chantier</h1>
    </div>
    <div class="h2 mb-0 ls-tight text-sm-end">
      <a
        (click)="goToUpdateSite()"
        class="btn d-inline-flex btn-sm btn-primary mx-1"
      >
        <span class="button_list" style="color: #fff">Modifier</span>
      </a>
    </div>
    <br />
    <div *ngIf="site" class="card shadow-sm">
      <div class="card-header bg-transparent border-0">
        <h3 class="mb-0">
          <i class="bi bi-info-circle-fill"></i> Informations générales
        </h3>
      </div>
      <div class="card-body row">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Établissement :</strong>
          <p *ngIf="site.etablissement" class="mb-3 title-info">
            {{ site.etablissement.name }}
          </p>
          <div *ngIf="!site.etablissement" class="mb-3 title-info no-data">
            <span class="aucun-text">Aucun</span>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Nom :</strong>
          <p class="mb-3 title-info">{{ site.name }}</p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Numéro :</strong>
          <p class="mb-3 title-info">{{ site.numberSite }}</p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Adresse :</strong>
          <p class="mb3 title-info">
            {{ site.adress[0].street }}, {{ site.adress[0].number }}
          </p>
          <p class="mb3 title-info">
            {{ site.adress[0].postalCode }} {{ site.adress[0].city }}
          </p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Responsable :</strong>
          <p class="mb-3 title-info">{{ site.manager.firstName }}</p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Date de début :</strong>
          <p class="mb-3 title-info">
            <span class="aucun-text" *ngIf="!site.startDate">Aucun</span>
            {{ site.startDate | date : "dd/MM/yyyy" }}
          </p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Type :</strong>
          <p class="mb-3 title-info">{{ site.type }}</p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Étage du chantier :</strong>
          <p class="mb-3 title-info">
            <span class="aucun-text" *ngIf="!site.floor">Aucun</span>
            {{ site.floor }}
          </p>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <strong class="pr-1 title-info">Statut :</strong>
          <p class="mb-3 title-info">
            <span *ngIf="site.statut == 'STOP'" class="badge badge-danger">{{
              site.statut
            }}</span>
            <span
              *ngIf="site.statut == 'ACTIF'"
              class="badge badge-pill badge-success"
              >{{ site.statut }}</span
            >
            <span
              *ngIf="site.statut == 'ONE SHOT'"
              class="badge badge-pill badge-info"
              >{{ site.statut }}</span
            >
            <span
              *ngIf="site.statut == 'OFFRE'"
              class="badge badge-pill badge-orange"
              >{{ site.statut }}</span
            >
          </p>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-12">
          <strong class="pr-1 title-info">Note :</strong>
          <p *ngIf="!site.note" class="mb-3 title-info">
            <span class="aucun-text">Aucun</span>
          </p>
          <p
            *ngIf="site.note"
            class="mb-3 title-info"
            [innerHTML]="site.note"
          ></p>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div
        routerLink="/admin/clients/details/{{ site.client._id }}"
        style="cursor: pointer"
        *ngIf="site && site.client"
        class="card shadow-sm"
      >
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0">
            <i class="bi bi-people-fill pr-1"></i> Informations du client
          </h3>
        </div>
        <div class="card-body row">
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <strong class="pr-1 title-info">Nom :</strong>
            <p class="mb-3 title-info">{{ site.client.companyName }}</p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <strong class="pr-1 title-info">TVA :</strong>
            <p class="mb-3 title-info">
              <span class="aucun-text" *ngIf="!site.client.tva">Aucun</span>
              {{ site.client.tva }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <strong class="pr-1 title-info">Contact principal :</strong>
            <p *ngIf="site.client.contactMain" class="mb-3 title-info">
              {{ site.client.contactMain.lastName }}
              {{ site.client.contactMain.firstName }}
            </p>
            <p *ngIf="!site.client.contactMain" class="mb-3 title-info">
              <span class="aucun-text">Aucun</span>
            </p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <strong class="pr-1 title-info"
              >Adresse mail de facturation :</strong
            >
            <p class="mb-3 title-info">
              <span class="aucun-text" *ngIf="!site.client.email">Aucun</span>
              {{ site.client.email }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0">
            <i class="bi bi-card-checklist pr-1"></i>Liste des prestations de
            {{ site.name }}
          </h3>
          <div *ngIf="site.statut != 'STOP'" class="ml-auto">
            <a
              class="btn d-inline-flex btn-sm btn-primary mx-1"
              style="float: inline-end"
              (click)="addBenefit()"
            >
              <span class="button_list" style="color: #fff"
                >Créer une prestation</span
              >
            </a>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr
                  style="
                    border-bottom: 1px solid #e7eaf0;
                    border-top-color: transparent;
                  "
                >
                  <th scope="col">Chantier</th>
                  <th scope="col">Type de prestation</th>
                  <th scope="col">Fréquence</th>
                  <th scope="col">Durée</th>
                  <th scope="col">date de début</th>
                  <th scope="col">date de fin</th>
                  <!-- <th scope="col">Prestataire</th> -->
                </tr>
              </thead>
              <tbody *ngFor="let i of benefits; let k = index">
                <tr
                  (click)="getBenefit(i)"
                  *ngIf="k % 2 != 0"
                  style="background-color: #e9ebff; border: hidden"
                >
                  <td (click)="details(i._id)">{{ i.site }}</td>
                  <td (click)="details(i._id)">{{ i.name }}</td>
                  <td
                    *ngIf="i.frequence == 'Hebdomadaire'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Blue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mensuelle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Pink">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 1-4-7-10'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Green">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 2-5-8-11'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Peach">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 3-6-9-12'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Lavender">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Quadrimestrielle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-SkyBlue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Semestrielle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Salmon">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Annuelle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Grey">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Bi-annuelle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Black">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Quotidienne'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Yellow">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Tous les 15 jours'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-PickBlue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mois pairs'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-MoisPair">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mois impairs'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-MoisImpair">{{
                      i.frequence
                    }}</span>
                  </td>

                  <td (click)="details(i._id)">
                    <span class="aucun-text" *ngIf="!i.duration">Aucun</span>
                    {{ i.duration }}
                  </td>
                  <td (click)="details(i._id)">
                    {{ i.startDate | date : "dd/MM/yyyy" }}
                  </td>
                  <td (click)="details(i._id)">
                    {{ i.endDate | date : "dd/MM/yyyy" }}
                  </td>
                  <!-- <td (click)="details(i._id)">{{ i.subContractor }}</td> -->
                </tr>
                <tr
                  (click)="getBenefit(i)"
                  *ngIf="k % 2 == 0"
                  style="background-color: white; border-bottom: hidden"
                >
                  <td (click)="details(i._id)">{{ i.site }}</td>
                  <td (click)="details(i._id)">{{ i.name }}</td>
                  <td
                    *ngIf="i.frequence == 'Hebdomadaire'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Blue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mensuelle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Pink">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 1-4-7-10'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Green">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 2-5-8-11'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Peach">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 3-6-9-12'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Lavender">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Quadrimestrielle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-SkyBlue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Semestrielle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Salmon">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Annuelle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Grey">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Bi-annuelle'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Black">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Quotidienne'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-Yellow">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Tous les 15 jours'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-PickBlue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mois pairs'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-MoisPair">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mois impairs'"
                    style="cursor: pointer"
                    (click)="details(i._id)"
                  >
                    <span class="badge badge-MoisImpair">{{
                      i.frequence
                    }}</span>
                  </td>

                  <!-- <td  (click)="details(i._id)">{{i.duration}}</td> -->
                  <td (click)="details(i._id)">
                    <span class="aucun-text" *ngIf="!i.duration">Aucun</span>
                    {{ i.duration }}
                  </td>
                  <td (click)="details(i._id)">
                    {{ i.startDate | date : "dd/MM/yyyy" }}
                  </td>
                  <td (click)="details(i._id)">
                    {{ i.endDate | date : "dd/MM/yyyy" }}
                  </td>
                  <!-- <td (click)="details(i._id)">{{ i.subContractor }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="benefits && benefits.length == 0"
            class="table-responsive"
            style="text-align: center"
          >
            <p style="padding-bottom: 45px; padding-top: 35px">
              Aucune prestation n'est trouvée
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0">
            <i class="bi bi-card-checklist pr-1"></i>Liste des prestations
            vitres de {{ site.name }}
          </h3>
          <div *ngIf="site.statut != 'STOP'" class="ml-auto">
            <a
              class="btn d-inline-flex btn-sm btn-primary mx-1"
              style="float: inline-end"
              (click)="addBenefitGlass()"
            >
              <span class="button_list" style="color: #fff"
                >Créer une prestation vitres</span
              >
            </a>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr
                  style="
                    border-bottom: 1px solid #e7eaf0;
                    border-top-color: transparent;
                  "
                >
                  <th scope="col">Chantier</th>
                  <th scope="col">Type de prestation</th>
                  <th scope="col">Fréquence</th>
                  <th scope="col">date de début</th>
                  <th scope="col">date de fin</th>
                  <th scope="col">Prestataire</th>
                </tr>
              </thead>
              <tbody *ngFor="let i of benefitsGlass; let k = index">
                <tr
                  (click)="detailsBenfitGlass(i._id)"
                  *ngIf="k % 2 != 0"
                  style="background-color: #e9ebff; border: hidden"
                >
                  <td (click)="detailsBenfitGlass(i._id)">{{ i.site }}</td>
                  <td (click)="detailsBenfitGlass(i._id)">{{ i.type }}</td>
                  <td
                    *ngIf="i.frequence == 'Hebdomadaire'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Blue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mensuelle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Pink">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 1-4-7-10'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Green">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 2-5-8-11'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Peach">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 3-6-9-12'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Lavender">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Quadrimestrielle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-SkyBlue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Semestrielle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Salmon">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Annuelle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Grey">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Bi-annuelle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Black">{{ i.frequence }}</span>
                  </td>
                  <!-- <td *ngIf="i.frequence == 'Quotidienne' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Yellow">{{i.frequence}}</span></td> -->
                  <!-- <td *ngIf="i.frequence == 'Tous les 15 jours' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-PickBlue">{{i.frequence}}</span></td> -->

                  <td (click)="detailsBenfitGlass(i._id)">
                    {{ i.startDate | date : "dd/MM/yyyy" }}
                  </td>
                  <td (click)="detailsBenfitGlass(i._id)">
                    {{ i.endDate | date : "dd/MM/yyyy" }}
                  </td>
                  <td (click)="detailsBenfitGlass(i._id)">
                    {{ i.subContractor }}
                  </td>
                </tr>
                <tr
                  (click)="detailsBenfitGlass(i._id)"
                  *ngIf="k % 2 == 0"
                  style="background-color: white; border-bottom: hidden"
                >
                  <td (click)="detailsBenfitGlass(i._id)">{{ i.site }}</td>
                  <td (click)="detailsBenfitGlass(i._id)">{{ i.type }}</td>
                  <td
                    *ngIf="i.frequence == 'Hebdomadaire'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Blue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Mensuelle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Pink">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 1-4-7-10'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Green">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 2-5-8-11'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Peach">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Trimestrielle 3-6-9-12'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Lavender">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Quadrimestrielle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-SkyBlue">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Semestrielle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Salmon">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Annuelle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Grey">{{ i.frequence }}</span>
                  </td>
                  <td
                    *ngIf="i.frequence == 'Bi-annuelle'"
                    style="cursor: pointer"
                    (click)="detailsBenfitGlass(i._id)"
                  >
                    <span class="badge badge-Black">{{ i.frequence }}</span>
                  </td>
                  <!-- <td *ngIf="i.frequence == 'Quotidienne' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Yellow">{{i.frequence}}</span></td> -->
                  <!-- <td *ngIf="i.frequence == 'Tous les 15 jours' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-PickBlue">{{i.frequence}}</span></td> -->

                  <td (click)="detailsBenfitGlass(i._id)">
                    {{ i.startDate | date : "dd/MM/yyyy" }}
                  </td>
                  <td (click)="detailsBenfitGlass(i._id)">
                    {{ i.endDate | date : "dd/MM/yyyy" }}
                  </td>
                  <td (click)="detailsBenfitGlass(i._id)">
                    {{ i.subContractor }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="benefitsGlass && benefitsGlass.length == 0"
            class="table-responsive"
            style="text-align: center"
          >
            <p style="padding-bottom: 45px; padding-top: 35px">
              Aucune prestation vitres n'est trouvée
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div>
      <div class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0">
            <i class="bi bi-calculator active pr-1"></i>Liste des Suppafacs de
            {{ site.name }}
          </h3>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr
                  style="
                    border-bottom: 1px solid #e7eaf0;
                    border-top-color: transparent;
                  "
                >
                  <th scope="col">Date</th>
                  <th scope="col">Titre suppafac</th>
                  <th scope="col">Contact</th>
                  <th scope="col">statut</th>
                </tr>
              </thead>
              <tbody *ngFor="let i of suppafacs; let k = index">
                <tr
                  *ngIf="k % 2 != 0"
                  style="background-color: #e9ebff; border: hidden"
                  routerLink="/admin/chantiers/suppafacs/details/{{ i._id }}"
                >
                  <td>
                    <span class="aucun-text" *ngIf="!i.date">Aucun</span>
                    {{ i.date | date : "dd/MM/yyyy" }}
                  </td>
                  <td>{{ i.title }}</td>
                  <td>{{ i.contact }}</td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'Clôturé'">
                    <span class="badge badge-closed-gray">{{ i.statut }}</span>
                  </td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'En cours'">
                    <span class="badge badge-pill badge-inProgress-red">{{
                      i.statut
                    }}</span>
                  </td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'Planifié'">
                    <span class="badge badge-pill badge-yellow-cheduled">{{
                      i.statut
                    }}</span>
                  </td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'A faire'">
                    <span class="badge badge-pill badge-Blue-toDo">{{
                      i.statut
                    }}</span>
                  </td>
                </tr>
                <tr
                  *ngIf="k % 2 == 0"
                  style="background-color: white; border-bottom: hidden"
                  routerLink="/admin/chantiers/suppafacs/details/{{ i._id }}"
                >
                  <td>
                    <span class="aucun-text" *ngIf="!i.date">Aucun</span>
                    {{ i.date | date : "dd/MM/yyyy" }}
                  </td>
                  <td>{{ i.title }}</td>
                  <td>{{ i.contact }}</td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'Clôturé'">
                    <span class="badge badge-closed-gray">{{ i.statut }}</span>
                  </td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'En cours'">
                    <span class="badge badge-pill badge-inProgress-red">{{
                      i.statut
                    }}</span>
                  </td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'Planifié'">
                    <span class="badge badge-pill badge-yellow-cheduled">{{
                      i.statut
                    }}</span>
                  </td>
                  <td (click)="details(i._id)" *ngIf="i.statut == 'A faire'">
                    <span class="badge badge-pill badge-Blue-toDo">{{
                      i.statut
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="suppafacs && suppafacs.length == 0"
            class="table-responsive"
            style="text-align: center"
          >
            <p style="padding-bottom: 45px; padding-top: 35px">
              Aucun suppafac n'est trouvé
            </p>
          </div>
          <!-- <div
            class="table-responsive" style="text-align: center">
            <p style="padding-bottom: 45px; padding-top: 35px ">
              En cours de développement
            </p>
            </div> -->
        </div>
      </div>
    </div>
    <br />
    <div>
      <div class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0">
            <i class="bi bi-diagram-3 active pr-1"></i>Liste des ouvries de
            {{ site.name }}
          </h3>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr
                  style="
                    border-bottom: 1px solid #e7eaf0;
                    border-top-color: transparent;
                  "
                >
                  <th scope="col">Prénom</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Téléphone</th>
                </tr>
              </thead>
              <tbody *ngFor="let worker of site.worker; let k = index">
                <tr
                  *ngIf="k % 2 != 0"
                  style="background-color: #e9ebff; border: hidden"
                  routerLink="/admin/ouvriers/details/{{ worker._id }}"
                >
                  <td>{{ worker.firstName }}</td>
                  <td>{{ worker.lastName }}</td>
                  <td>{{ worker.phone }}</td>
                </tr>
                <tr
                  *ngIf="k % 2 == 0"
                  style="background-color: white; border-bottom: hidden"
                  routerLink="/admin/ouvriers/details/{{ worker._id }}"
                >
                  <td>{{ worker.firstName }}</td>
                  <td>{{ worker.lastName }}</td>
                  <td>{{ worker.phone }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="site && site.worker && site.worker.length == 0"
            class="table-responsive"
            style="text-align: center"
          >
            <p style="padding-bottom: 45px; padding-top: 35px">
              Aucun ouvrier n'est trouvé
            </p>
          </div>
          <!-- <div
            class="table-responsive" style="text-align: center">
            <p style="padding-bottom: 45px; padding-top: 35px ">
              En cours de développement
            </p>
            </div> -->
        </div>
      </div>
    </div>
    <br />
    <div>
      <div class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0">
            <i class="bi bi-file-person-fill pr-1"></i>Liste des contacts de
            {{ site.name }}
          </h3>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr
                  style="
                    border-bottom: 1px solid #e7eaf0;
                    border-top-color: transparent;
                  "
                >
                  <th scope="col">Prénom</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Adresse mail</th>
                  <th scope="col">Téléphone</th>
                  <!-- <th scope="col">Actions</th> -->
                </tr>
              </thead>
              <tbody *ngFor="let i of contacts; let k = index">
                <tr
                  *ngIf="k % 2 != 0"
                  style="
                    background-color: #e9ebff;
                    border: hidden;
                    cursor: pointer;
                  "
                  routerLink="/admin/contacts/details/{{ i._id }}"
                >
                  <td>
                    <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                    {{ i.lastName }}
                  </td>
                  <td>
                    <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                    {{ i.firstName }}
                  </td>
                  <td>
                    <span class="aucun-text" *ngIf="!i.email">Aucun</span>
                    {{ i.email }}
                  </td>
                  <td>
                    <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                    {{ i.phone }}
                  </td>
                </tr>
                <tr
                  *ngIf="k % 2 == 0"
                  style="
                    background-color: white;
                    border-bottom: hidden;
                    cursor: pointer;
                  "
                  routerLink="/admin/contacts/details/{{ i._id }}"
                >
                  <td>
                    <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                    {{ i.lastName }}
                  </td>
                  <td>
                    <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                    {{ i.firstName }}
                  </td>
                  <td>
                    <span class="aucun-text" *ngIf="!i.email">Aucun</span>
                    {{ i.email }}
                  </td>
                  <td>
                    <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                    {{ i.phone }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="contacts && contacts.length == 0"
            class="table-responsive"
            style="text-align: center"
          >
            <p style="padding-bottom: 45px; padding-top: 35px">
              Aucun contact n'est trouvé
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <div class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0">
            <i class="bi bi-file-earmark-text-fill pr-1"></i>Liste des documents
            de {{ site.name }}
          </h3>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <!-- <h2>fff  {{listFiles | json }}</h2> -->
              <tbody *ngIf="listFiles" class="upload-name-style">
                <tr *ngFor="let item of listFiles; let i = index">
                  <td (click)="openFileInNewWindow(site.key, item)">
                    <strong>{{ item }}</strong>
                  </td>
                  <!-- <td><i (click)="deleteFileFromserver(myUUID,item)" style="color: #0e309f;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i></td> -->
                  <td>
                    <button
                      class="btn btn-sm btn-square btn-neutral text-danger-hover"
                    >
                      <i
                        class="bi bi-eye"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Visualiser"
                        (click)="openFileInNewWindow(site.key, item)"
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="listFiles && listFiles.length == 0"
            class="table-responsive"
            style="text-align: center"
          >
            <p style="padding-bottom: 45px; padding-top: 35px">
              Aucun document n'est trouvé
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</div>
