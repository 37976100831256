<app-navbar></app-navbar>
<br />
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item">
        <a class="color-blue" style="cursor: default"
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li class="breadcrumb-item active color-blue" style="cursor: default">
        Chantiers
      </li>
    </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%">
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1
            *ngIf="archived == false"
            class="h2 mb-0 ls-tight color-blue title-1"
          >
            Liste des chantiers
          </h1>
          <h1
            *ngIf="archived == true"
            class="h2 mb-0 ls-tight color-blue title-1"
          >
            Liste des chantiers archivés
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>

        <!-- Actions -->
        <div
          *ngIf="archived == false"
          class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mt-4 text-sm-end"
        >
          <div class="mx-n1">
            <a
              (click)="create()"
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
              <span class="button_list" style="color: #fff"
                >Créer un chantier</span
              >
            </a>
            <a
              *ngIf="sites && sites.length > 0"
              (click)="exportToExcel(sites)"
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
              <span class="button_list" style="color: #fff">Export XLS</span>
            </a>
            <a
              *ngIf="sites && sites.length == 0"
              style="cursor: not-allowed"
              class="btn d-inline-flex btn-sm btn-disabled mx-1"
            >
              <span class="button_list">Export XLS</span>
            </a>
          </div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0"></ul>
    </div>
  </div>
</header>

<main *ngIf="archived == false" class="py-6 bg-surface-secondary">
  <div class="container-fluid" style="width: 86%">
    <div class="card shadow border-0 mb-7">
      <div class="card-header col-12">
        <div class="row">
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
            <h4 class="titlepage">Chantiers</h4>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
            <h4 (click)="displayListArchivedSites()" class="titleotherpage">
              Chantiers archivés
            </h4>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <div class="row">
          <div class="row col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12">
            <div
              class="form-outline col-xl-2 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">N° chantier</label>
              <input
                type="search"
                id="form1"
                [(ngModel)]="numberSiteToFiltred"
                class="form-control"
                (input)="filter()"
              />
            </div>
            <div
              class="form-outline col-xl-2 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Nom</label>
              <input
                type="search"
                id="form1"
                [(ngModel)]="nameToToFiltred"
                class="form-control"
                (input)="filter()"
              />
            </div>
            <div
              class="form-outline col-xl-3 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Adresse</label>
              <input
                type="search"
                id="form1"
                [(ngModel)]="siteAdressToFiltred"
                class="form-control"
                (input)="filter()"
              />
            </div>
            <div
              class="form-outline col-xl-3 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Ville</label>
              <ng-select
                [items]="listCities"
                (change)="filter()"
                class="form-control input-custom"
                [closeOnSelect]="true"
                [(ngModel)]="citiesToFiltred"
              >
              </ng-select>
            </div>
            <div
              class="form-outline col-xl-2 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Statut</label>
              <ng-select
                [items]="statuts"
                (change)="filter()"
                class="form-control input-custom"
                [closeOnSelect]="true"
                [(ngModel)]="statutToFiltred"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-sm-1 col-2">
            <label class="form-label"></label>
            <div class="d-flex justify-content-between">
              <!-- <button (click)="filter()" class="btn btn-primary p-2 mr-1">
                <i class="fa fa-search"></i>
              </button> -->
              <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <style>
                    svg {
                      fill: #ffffff;
                    }
                  </style>
                  <path
                    d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr
              style="
                border-bottom: 1px solid #e7eaf0;
                border-top-color: transparent;
              "
            >
              <th
                style="cursor: pointer"
                (click)="sort('column2', decend, col2)"
                scope="col"
              >
                N° chantier
                <i *ngIf="col2 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col2 == true" class="bi bi-chevron-up"></i>
              </th>
              <th
                style="cursor: pointer"
                (click)="sort('column3', decend, col3)"
                scope="col"
              >
                Nom
                <i *ngIf="col3 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col3 == true" class="bi bi-chevron-up"></i>
              </th>
              <th
                style="cursor: pointer"
                (click)="sort('adress', decend, col4)"
                scope="col"
              >
                Adresse
                <i *ngIf="col4 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col4 == true" class="bi bi-chevron-up"></i>
              </th>
              <th
                style="cursor: pointer"
                (click)="sort('column6', decend, col6)"
                scope="col"
              >
                Statut
                <i *ngIf="col6 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col6 == true" class="bi bi-chevron-up"></i>
              </th>
              <th scope="col">Actions</th>
              <!-- <th scope="col">Actions</th> -->
            </tr>
          </thead>
          <tbody
            *ngFor="
              let i of sites
                | sort : criteria
                | paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };
              let k = index
            "
          >
            <tr
              *ngIf="k % 2 != 0"
              style="background-color: #e9ebff; border: hidden"
            >
              <td (click)="details(i._id)">{{ i.column2 }}</td>
              <td (click)="details(i._id)">{{ i.column3 }}</td>
              <td (click)="details(i._id)">
                {{ getAddressParts(i.adress).street }},
                {{ getAddressParts(i.adress).number }} <br />
                {{ getAddressParts(i.adress).postalCode }}
                {{ getAddressParts(i.adress).city }}
              </td>
              <td (click)="togglePopup(i._id)">
                <span class="badge" [ngClass]="getBadgeClass(i.column6)">
                  {{ i.column6 }}
                  <i class="fa fa-chevron-down"></i>
                </span>
                <div
                  *ngIf="isPopupOpen(i._id)"
                  class="popup-container"
                  [attr.data-id]="i._id"
                >
                  <ul>
                    <li
                      *ngFor="let status of statuses"
                      (mouseover)="hoverStatus = status"
                      (mouseout)="hoverStatus = ''"
                      (click)="selectStatus(i._id, status)"
                      [ngClass]="{
                        'active-status': hoverStatus === status
                      }"
                      style="
                        padding-bottom: 0.3rem;
                        list-style-type: none !important;
                        margin-left: -20px !important;
                      "
                    >
                      <span [ngClass]="getBadgeClass(status)">
                        {{ status }}
                      </span>
                    </li>
                  </ul>
                  <!-- <button (click)="togglePopup(i._id)">Fermer</button> -->
                </div>
              </td>

              <td>
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                >
                  <i
                    class="bi bi-pencil-square"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Modifier"
                    (click)="update(i._id)"
                  ></i>
                </button>
                <button
                  title="Créer une prestation"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                  (click)="addBenefit(i._id)"
                >
                  <i class="bi bi-card-checklist"></i>
                </button>
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                >
                  <i
                    class="bi bi-archive"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Archiver"
                    data-toggle="modal"
                    data-target="#exampleModalArchiver"
                    (click)="getsite(i)"
                  ></i>
                </button>
                <!-- <button
  *ngIf="siteAdressToFiltred ||
  nameToToFiltred  ||
  numberSiteToFiltred ||
  statutToFiltred ||
  citiesToFiltred
  "
  class="btn btn-sm btn-square btn-neutral text-danger-hover"
  (click)="details(i._id)"
>
<i class="bi bi-eye"></i>
</button> -->
              </td>
            </tr>
            <tr
              *ngIf="k % 2 == 0"
              style="background-color: white; border-bottom: hidden"
            >
              <td (click)="details(i._id)">{{ i.column2 }}</td>
              <td (click)="details(i._id)">{{ i.column3 }}</td>
              <td (click)="details(i._id)">
                {{ getAddressParts(i.adress).street }},
                {{ getAddressParts(i.adress).number }} <br />
                {{ getAddressParts(i.adress).postalCode }}
                {{ getAddressParts(i.adress).city }}
              </td>
              <td (click)="togglePopup(i._id)">
                <span class="badge" [ngClass]="getBadgeClass(i.column6)">
                  {{ i.column6 }}
                  <i class="fa fa-chevron-down"></i>
                </span>
                <div
                  *ngIf="isPopupOpen(i._id)"
                  class="popup-container"
                  [attr.data-id]="i._id"
                >
                  <ul>
                    <li
                      *ngFor="let status of statuses"
                      (mouseover)="hoverStatus = status"
                      (mouseout)="hoverStatus = ''"
                      (click)="selectStatus(i._id, status)"
                      [ngClass]="{
                        'active-status': hoverStatus === status
                      }"
                      style="
                        padding-bottom: 0.3rem;
                        list-style-type: none !important;
                        margin-left: -20px !important;
                      "
                    >
                      <span [ngClass]="getBadgeClass(status)">
                        {{ status }}
                      </span>
                    </li>
                  </ul>
                  <!-- <button (click)="togglePopup(i._id)">Fermer</button> -->
                </div>
              </td>

              <td>
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                >
                  <i
                    class="bi bi-pencil-square"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Modifier"
                    (click)="update(i._id)"
                  ></i>
                </button>
                <button
                  title="Créer une prestation"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                  (click)="addBenefit(i._id)"
                >
                  <i class="bi bi-card-checklist"></i>
                </button>
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                >
                  <i
                    class="bi bi-archive"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Archiver"
                    data-toggle="modal"
                    data-target="#exampleModalArchiver"
                    (click)="getsite(i)"
                  ></i>
                </button>
                <!-- <button
  *ngIf="siteAdressToFiltred ||
  nameToToFiltred ||
  numberSiteToFiltred ||
  statutToFiltred ||
  citiesToFiltred
  "
  class="btn btn-sm btn-square btn-neutral text-danger-hover"
  (click)="details(i._id)"
>
<i class="bi bi-eye"></i>
</button> -->
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="sites && sites.length > 10"
          class="card-footer border-0 py-5"
        >
          <div class="d-flex justify-content-start">
            <pagination-controls
              class="my-pagination"
              previousLabel="Précédent"
              nextLabel="Suivant"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
          </div>
        </div>
        <div
          *ngIf="sites && sites.length == 0"
          class="table-responsive"
          style="text-align: center"
        >
          <p style="padding-bottom: 45px; padding-top: 35px">
            Aucun chantier n'est trouvé
          </p>
        </div>
        <br />
      </div>
    </div>
  </div>
</main>

<main *ngIf="archived == true" class="py-6 bg-surface-secondary">
  <div class="container-fluid" style="width: 86%">
    <div class="card shadow border-0 mb-7">
      <div class="card-header col-12">
        <div class="row">
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
            <h4 (click)="displayListSites()" class="titleotherpage">
              Chantiers
            </h4>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
            <h4 class="titlepage">Chantiers archivés</h4>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <div class="row">
          <div class="row col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12">
            <div
              class="form-outline col-xl-2 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">N° chantier</label>
              <input
                type="search"
                id="form1"
                [(ngModel)]="numberSiteToFiltred"
                class="form-control"
                (input)="filter()"
              />
            </div>
            <div
              class="form-outline col-xl-2 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Nom</label>
              <input
                type="search"
                id="form1"
                [(ngModel)]="nameToToFiltred"
                class="form-control"
                (input)="filter()"
              />
            </div>
            <div
              class="form-outline col-xl-3 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Adresse</label>
              <input
                type="search"
                id="form1"
                [(ngModel)]="siteAdressToFiltred"
                class="form-control"
                (input)="filter()"
              />
            </div>
            <div
              class="form-outline col-xl-3 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Ville</label>
              <ng-select
                [items]="listCities"
                (change)="filter()"
                class="form-control input-custom"
                [closeOnSelect]="true"
                [(ngModel)]="citiesToFiltred"
              >
              </ng-select>
            </div>
            <div
              class="form-outline col-xl-2 col-4-cust col-lg-4 col-md-6 col-sm-6"
            >
              <label class="form-label" for="form1">Statut</label>
              <ng-select
                [items]="statuts"
                (change)="filter()"
                class="form-control input-custom"
                [closeOnSelect]="true"
                [(ngModel)]="statutToFiltred"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-sm-1 col-2">
            <label class="form-label"></label>
            <div class="d-flex justify-content-between">
              <!-- <button (click)="filter()" class="btn btn-primary p-2 mr-1">
                <i class="fa fa-search"></i>
              </button> -->
              <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <style>
                    svg {
                      fill: #ffffff;
                    }
                  </style>
                  <path
                    d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr
              style="
                border-bottom: 1px solid #e7eaf0;
                border-top-color: transparent;
              "
            >
              <th
                style="cursor: pointer"
                (click)="sort('column2', decend, col2)"
                scope="col"
              >
                N° chantier
                <i *ngIf="col2 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col2 == true" class="bi bi-chevron-up"></i>
              </th>
              <th
                style="cursor: pointer"
                (click)="sort('column3', decend, col3)"
                scope="col"
              >
                Nom
                <i *ngIf="col3 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col3 == true" class="bi bi-chevron-up"></i>
              </th>
              <th
                style="cursor: pointer"
                (click)="sort('adress', decend, col4)"
                scope="col"
              >
                Adresse
                <i *ngIf="col4 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col4 == true" class="bi bi-chevron-up"></i>
              </th>
              <th
                style="cursor: pointer"
                (click)="sort('column6', decend, col6)"
                scope="col"
              >
                Statut
                <i *ngIf="col6 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col6 == true" class="bi bi-chevron-up"></i>
              </th>
              <th scope="col">Actions</th>
              <!-- <th scope="col">Actions</th> -->
            </tr>
          </thead>
          <tbody
            *ngFor="
              let i of sites
                | sort : criteria
                | paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };
              let k = index
            "
          >
            <tr
              *ngIf="k % 2 != 0"
              style="background-color: #e9ebff; border: hidden"
            >
              <td (click)="detailsArchive(i._id)">{{ i.column2 }}</td>
              <td (click)="detailsArchive(i._id)">{{ i.column3 }}</td>
              <td (click)="detailsArchive(i._id)">
                {{ getAddressParts(i.adress).street }},
                {{ getAddressParts(i.adress).number }} <br />
                {{ getAddressParts(i.adress).postalCode }}
                {{ getAddressParts(i.adress).city }}
              </td>
              <td (click)="detailsArchive(i._id)" *ngIf="i.column6 == 'STOP'">
                <span class="badge badge-danger">{{ i.column6 }}</span>
              </td>
              <td (click)="detailsArchive(i._id)" *ngIf="i.column6 == 'ACTIF'">
                <span class="badge badge-pill badge-success">{{
                  i.column6
                }}</span>
              </td>
              <td
                (click)="detailsArchive(i._id)"
                *ngIf="i.column6 == 'ONE SHOT'"
              >
                <span class="badge badge-pill badge-info">{{ i.column6 }}</span>
              </td>
              <td (click)="detailsArchive(i._id)" *ngIf="i.column6 == 'OFFRE'">
                <span class="badge badge-pill badge-orange">{{
                  i.column6
                }}</span>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover"
                >
                  <i
                    class="fa fa-reply archived"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Désarchiver"
                    data-toggle="modal"
                    data-target="#exampleModalArchiver"
                    (click)="getsite(i)"
                  ></i>
                </button>
              </td>
            </tr>
            <tr
              *ngIf="k % 2 == 0"
              style="background-color: white; border-bottom: hidden"
            >
              <td (click)="detailsArchive(i._id)">{{ i.column2 }}</td>
              <td (click)="detailsArchive(i._id)">{{ i.column3 }}</td>
              <td (click)="detailsArchive(i._id)">
                {{ getAddressParts(i.adress).street }},
                {{ getAddressParts(i.adress).number }} <br />
                {{ getAddressParts(i.adress).postalCode }}
                {{ getAddressParts(i.adress).city }}
              </td>
              <td (click)="detailsArchive(i._id)" *ngIf="i.column6 == 'STOP'">
                <span class="badge badge-danger">{{ i.column6 }}</span>
              </td>
              <td (click)="detailsArchive(i._id)" *ngIf="i.column6 == 'ACTIF'">
                <span class="badge badge-pill badge-success">{{
                  i.column6
                }}</span>
              </td>
              <td
                (click)="detailsArchive(i._id)"
                *ngIf="i.column6 == 'ONE SHOT'"
              >
                <span class="badge badge-pill badge-info">{{ i.column6 }}</span>
              </td>
              <td (click)="detailsArchive(i._id)" *ngIf="i.column6 == 'OFFRE'">
                <span class="badge badge-pill badge-orange">{{
                  i.column6
                }}</span>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover"
                >
                  <i
                    class="fa fa-reply archived"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Désarchiver"
                    data-toggle="modal"
                    data-target="#exampleModalArchiver"
                    (click)="getsite(i)"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="sites && sites.length > 10"
          class="card-footer border-0 py-5"
        >
          <div class="d-flex justify-content-start">
            <pagination-controls
              class="my-pagination"
              previousLabel="Précédent"
              nextLabel="Suivant"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
          </div>
        </div>
        <div
          *ngIf="sites && sites.length == 0"
          class="table-responsive"
          style="text-align: center"
        >
          <p style="padding-bottom: 45px; padding-top: 35px">
            Aucun chantier archivé n'est trouvé
          </p>
        </div>

        <br />
      </div>
    </div>
  </div>
</main>

<!--archivage start -->

<div
  class="modal fade"
  id="exampleModalArchiver"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2
          *ngIf="etatSite == false"
          style="color: #0e309f"
          class="modal-title"
          id="exampleModalLabel"
        >
          Confirmation d'archivage
        </h2>
        <h2
          *ngIf="etatSite == true"
          style="color: #0e309f"
          class="modal-title"
          id="exampleModalLabel"
        >
          Confirmation de désarchivage
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <img src="assets/images/1.jpg" alt="" />
          </div>
          <div class="col-8">
            <h3
              *ngIf="etatSite == false"
              style="position: relative; top: 40px !important; color: #31394c"
            >
              Êtes-vous sûr(e) de vouloir archiver ce chantier ?
            </h3>
            <h3
              *ngIf="etatSite == true"
              style="position: relative; top: 40px !important; color: #31394c"
            >
              Êtes-vous sûr(e) de vouloir désarchiver ce chantier ?
            </h3>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          style="
            background: white !important;
            color: #0e309f !important;
            border: 1px solid #0e309f !important;
          "
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          Annuler
        </button>
        <button
          style="background: #0e309f !important; color: #fff !important"
          class="btn btn-add"
          data-dismiss="modal"
          (click)="archiveSite('i')"
        >
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>

<!--archivage end -->

<!--spinner-->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-pulse"
>
  <p style="font-size: 20px; color: white">Veuillez patienter ...</p>
</ngx-spinner>
