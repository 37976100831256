import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { BenefitGlassService } from 'src/app/_services/benefit-glass.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SiteService } from 'src/app/_services/site.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { TypebenefitGlassService } from 'src/app/_services/typeBenefitGlass';
import { PopupAddTypebenefitGlassComponent } from '../../../popupAdd/popup-add-typebenefit-glass/popup-add-typebenefit-glass.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-update-glass',
  templateUrl: './update-glass.component.html',
  styleUrls: ['./update-glass.component.css','../../../../../_shared/add_update.css']
})
export class UpdateGlassComponent implements OnInit {
  submitted = false;
  registerForm: FormGroup;
  id :any 
  benefit: any;
  typesBenefitGlass : any
frequences =["Hebdomadaire","Mensuelle","Trimestrielle 1-4-7-10","Trimestrielle 2-5-8-11","Trimestrielle 3-6-9-12",
  "Quadrimestrielle","Semestrielle","Annuelle","Bi-annuelle"]
  listMonthsannual =["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]


  typesMaterial = [];
  listMaterialsFromHtml = [] 


  d: string;
  d1: string;
  dateAuj = new Date();
  frequenceSelected :any =""
  sites: any;
  listSubContractors: any;
  contacts :any

  MonthSelectedforFrequenceAnual: number;
  monthChoised_frenquency_annuelle :String
  selectedMonthsBiAnnual : string[] = [];
  listMonthsannual_Bi_annual = []
  monthsChoised_frenquency_BiAnnuelle =[]
  MonthSelectedforFrequenceBi_annual :any
  initialfrenquency: any;
  listDates= [];
  hebdates: any;
   /*weekly*/
   dayToNumber = {
    Monday: "01",
    Tuesday: "02",
    Wednesday: "03",
    Thursday: "04",
    Friday: "05",
    saturday:"06",
    sunday:"07"
  };
  /*weekly*/
  validWeeklyBenefitForm: boolean;

  datesChooses = []
  monthChooses = []
  initialListTypesMaterial: any;







  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private siteService :SiteService,
    private subContractorService :SubContractorService,
    private contactService :ContactService,
    private benefitService :BenefitGlassService,
    private route : ActivatedRoute,
    public iziToast: Ng2IzitoastService,
    private typeBenefitGlassSerice :TypebenefitGlassService,
    private modalService: NgbModal,


    ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.benefitService.getDetailsBenefitGlass(this.id).subscribe(data => {
      this.benefit = data;
      this.frequenceSelected = this.benefit.frequence
      this.initialfrenquency = this.benefit.frequence
      this.initialListTypesMaterial = this.benefit.typesMaterial



      if(this.benefit.frequence == "Bi-annuelle" ){
      
        for (let mo of this.benefit.listMonths){
        var indice = mo;
       var indiceDecimal = parseInt(indice, 10);
       var month = this.listMonthsannual[indiceDecimal-1];
      this.monthsChoised_frenquency_BiAnnuelle.push(month)
        }
       }

      if(this.benefit.frequence == "Annuelle" ){
        var indice = this.benefit.listMonths;
       var indiceDecimal = parseInt(indice, 10);
       var month = this.listMonthsannual[indiceDecimal-1];
      this.monthChoised_frenquency_annuelle = month
       }
      
    //  this.contactPrincipal_sub_contrator.firstName_lastName = this.benefit.contact["lastName"]+" "+this.benefit.contact["firstName"]
    this.benefit.contact.firstName_lastName = this.benefit.contact["lastName"]+" "+this.benefit.contact["firstName"]

     
   })
   this.registerForm = this.formBuilder.group({
    site: ['', Validators.required],
    type: ['', Validators.required],
    subContractor : ['', Validators.required],
    contratSST :[false,],
    contact :['', Validators.required],
    description :['', Validators.maxLength(200)], 
    frequence : ['', Validators.required],
    startDate :['', Validators.required],
    typesMaterial : ['',],
    plaque :[''],
    controlCertificate :[false,],
    numerosOsiris:['', ],
    listMonths : ['', ],
    listDates : ['', ],
},);

this.registerForm.get('frequence').valueChanges.subscribe((option: string) => {
  this.updateFormControls(option);
});
this.registerForm.get('typesMaterial').valueChanges.subscribe((option: string) => {
  this.updateFormControlsPlaqueField(option);
});
this.d1 = this.dateAuj.toISOString().substr(0, 10);

this.getSites()
this.getSubContratcors()
this.getContacts()
this.getTypesBenefitsGlass()

}

getSites() {
this.siteService.getSites_not_Stopedt().subscribe((e) => {
this.sites = e;

this.sites.forEach(item => {
  item.name = item.column3;
}); 
});
}

getSubContratcors()
{
this.subContractorService.getList_subContractor().subscribe(
(data) => {
    this.listSubContractors = data
    })
}

getContacts(){
this.contactService.getContacts().subscribe(data => {
  this.contacts = data;  
    })              
    }  
getTypesBenefitsGlass(){
  this.typeBenefitGlassSerice.getTypesBenefitsGlass().subscribe(
  (data) => {
  this.typesBenefitGlass = data
      })
          }       
    

// frequnce controlle de saisie lors de saisie frequence dynamiquement de frequence à l'autre
private updateFormControls(option: string): void {
  if (option === 'Annuelle') {
    this.registerForm.addControl('listOfMonths', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonthsBiAnnual');
  
  }
  else if (option === 'Bi-annuelle') {
    this.registerForm.addControl('listOfMonthsBiAnnual', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonths');
  
  }
}

private updateFormControlsPlaqueField(option: string): void {
  if (option.includes('Camions')) {
    this.registerForm.addControl('plate', this.formBuilder.control('', Validators.required));  
  }
  else{
    this.registerForm.removeControl('plate');
  }
}

get f() { return this.registerForm.controls; }

onSubmit() {
 
  this.submitted = true;
  if (this.registerForm.invalid) {     
      return;
  }
  let currentDate = new Date(this.registerForm.value.startDate);
  currentDate.setFullYear(currentDate.getFullYear() + 2);
  var endDate = currentDate.toISOString().slice(0, 10);
  this.registerForm.value.endDate = endDate 

  let parts = this.registerForm.value.startDate.split('-');
  let month = parseInt(parts[1]);
  let dayOfMotnth = parseInt(parts[2]); 

  if(this.registerForm.value.frequence == "Mensuelle"){
    this.datesChooses =[]
    let day = parseInt(parts[2]).toString();
    if(day.length == 1 ){
       day ="0"+day
    }
    this.datesChooses.shift();
    this.datesChooses.push(day)
    }
    if(this.registerForm.value.frequence == "Tous les 15 jours"){
      let day = parseInt(parts[2]).toString();
      let month =parseInt(parts[1])
      if(day.length == 1 ){
         day ="0"+day
      }
      this.datesChooses.shift();
      this.datesChooses.push(day)
      this.monthChooses.shift();
      this.monthChooses.push(month)
      this.registerForm.value.listMonths = this.monthChooses 
      }

  let searchWord = "Trimestrielle"
  if ( (this.registerForm.value.frequence.indexOf(searchWord) !== -1)){   
    //month = month + 1

    // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
    //   month = month - 1
    // }
    this.datesChooses =[]
    if(month == 13){
      month = 1
    }
    this.monthChooses.push(month);
    for (let i = 1; i < 4; i++) {
      month += 3;
      if (month > 12) {
        month = month - 12;
      }
      this.monthChooses.push(month);
      this.datesChooses =["01","01","01","01"] 
     }
     this.registerForm.value.listMonths = this.monthChooses 
    }


    if(this.registerForm.value.frequence == "Quadrimestrielle"){
      //month = month + 1
      // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
      //   month = month - 1
      // }
      this.datesChooses =[]
      if(month == 13){
        month = 1
      }
       
      
      this.monthChooses.push(month);
      for (let i = 1; i < 3; i++) {
        month += 4;
        if (month > 12) {
          month = month - 12;
      }
        this.monthChooses.push(month);
        this.datesChooses =["01","01","01"] 
    }
    this.registerForm.value.listMonths = this.monthChooses 
  }


  if(this.registerForm.value.frequence == "Hebdomadaire"){
    if(!this.hebdates && this.initialfrenquency == "Hebdomadaire" ){
      this.hebdates = this.benefit.listDates
    }
    if(this.hebdates == undefined || this.hebdates.length == 0){
      this.validWeeklyBenefitForm = false
      return;
    }
  }


  if(this.registerForm.value.frequence == "Hebdomadaire"){
    this.datesChooses = this.hebdates
  }

  if(this.registerForm.value.frequence == "Quotidienne"){
    this.datesChooses =[]
    this.datesChooses = ["01","02","03","04","05"]
  }

  if(this.registerForm.value.frequence == "Semestrielle"){
    this.datesChooses =[]
    //month = month + 1
    // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
    //   month = month - 1
    // }
    if(month == 13){
      month = 1
    }
    this.monthChooses.push(month);

    for (let i = 1; i < 2; i++) {
      month += 6;
      if (month > 12) {
        month = month - 12;
    }
      this.monthChooses.push(month);
      this.datesChooses =["01","01"] 
    }
    this.registerForm.value.listMonths = this.monthChooses 
    }

    if(this.registerForm.value.frequence == "Annuelle"){
      this.datesChooses =[]
      this.monthChooses.push(this.MonthSelectedforFrequenceAnual)
      this.registerForm.value.listMonths = this.monthChooses
      this.datesChooses =["01"]  
    }

    if(this.registerForm.value.frequence == "Bi-annuelle"){
     
      this.datesChooses =[]
      if(!this.MonthSelectedforFrequenceBi_annual){
        this.MonthSelectedforFrequenceBi_annual = this.benefit.listMonths.map(month => parseInt(month, 10));
        // this.MonthSelectedforFrequenceBi_annual = this.benefit.listMonths
      }
     
      if(this.MonthSelectedforFrequenceBi_annual.length == 2)
      {
        this.registerForm.value.listMonths = this.MonthSelectedforFrequenceBi_annual
        this.datesChooses =["01","01"]   
      }
      else{
        this.iziToast.show({
          message:"Merci de choisir deux mois pour la fréquence Bi-annuelle.",
          messageColor:'#800f2f',
          titleColor:'#800f2f',
          progressBarColor:'#c9184a',
          icon:'bi-exclamation-diamond',	
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          //overlay:true,
          overlayClose:true,	
         });
        return false
      }
    }

 

  this.registerForm.value.listDates = this.datesChooses 
 let convertedlistMonths =[]

 if(this.registerForm.value.listMonths[0] == undefined){
  this.registerForm.value.listMonths =  this.benefit.listMonths
 }

 //if(this.registerForm.value.frequence != "Annuelle"){
  for(let month of this.registerForm.value.listMonths){
    if(month < 10){
      if(month.toString().length < 2)
      month = "0"+month
      convertedlistMonths.push(month)
    }
    else{
      convertedlistMonths.push(month.toString())
    }
  }
  

// }  
//  else{
//   convertedlistMonths = this.monthChooses
//  }
this.registerForm.value.listMonths = convertedlistMonths
  this.registerForm.value.site = this.registerForm.value.site._id
  this.registerForm.value.subContractor = this.registerForm.value.subContractor._id
  this.registerForm.value.contact = this.registerForm.value.contact._id
  this.registerForm.value.type = this.registerForm.value.type._id
  let listTypesMatreail = this.registerForm.value.typesMaterial
  if(this.registerForm.value.typesMaterial === this.initialListTypesMaterial){
    this.registerForm.value.typesMaterial = this.initialListTypesMaterial
  }
  else{
  if(this.registerForm.value.typesMaterial){
    let listNamesTypesMaterials = this.registerForm.value.typesMaterial.map(item => item.name);
      this.registerForm.value.typesMaterial = listNamesTypesMaterials
    }
  }


  this.registerForm.value.plaque = this.benefit.plaque

  if(this.registerForm.value.typesMaterial[0] == undefined){
    this.registerForm.value.typesMaterial =listTypesMatreail
  }
  if(this.registerForm.value.typesMaterial.includes('Camions') && this.registerForm.value.plaque == ''){
    return;
  }

  this.benefitService.editDataBenefitGlass(this.id,this.registerForm.value).subscribe(
    (data) => {
      this.router.navigate(['/admin/chantiers/details/'+this.registerForm.value.site ])
      //this.sharedService.setIdSiteSendTOBenefit("");
         this.iziToast.show({
          message: "Modification avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        }
        )
}



// calcul benefitGlass
    getFreguence(frequence ) {
     this.frequenceSelected = frequence;
      }

      recuperateListMaterials(material){
         if( material.length == 3){
          this.benefit.typesMaterial =[]
          this.typesMaterial = [
            {  name: 'Camions', disabled: false },
            {  name: 'Nacelles', disabled: false },
            {  name: 'Potences', disabled: false },
           
          ];
         }
        this.listMaterialsFromHtml = material 
        
      }
      
      
       
      initTypeMaterail()
      {

        //if()
       
        if(this.listMaterialsFromHtml.length  == 0)
        this.typesMaterial = [
          {  name: 'Camions', disabled: false },
          {  name: 'Nacelles', disabled: false },
          {  name: 'Potences', disabled: false },
         
        ];
        else {
          for (let type of this.listMaterialsFromHtml){
            if(type.name == "Nacelles"){
              this.typesMaterial = [
                {  name: 'Camions', disabled: false },
                {  name: 'Nacelles', disabled: false },
                {  name: 'Potences', disabled: true },
               
              ];
              break;
            }
            else if(type.name == "Potences"){
              this.typesMaterial = [
                {  name: 'Camions', disabled: false },
                {  name: 'Nacelles', disabled: true },
                {  name: 'Potences', disabled: false },
               
              ];
              break;
            }
            else{
              this.typesMaterial = [
                {  name: 'Camions', disabled: false },
                {  name: 'Nacelles', disabled: false },
                {  name: 'Potences', disabled: false },
               
              ];
            }
      
          }
        }
        
      
      
      }
      

      shouldShowAttestationControl(): boolean {
        return this.listMaterialsFromHtml.some(item => item.name === 'Nacelles' || item.name === 'Potences');
      }
      
      shouldShowPlaque(): boolean {
        return this.listMaterialsFromHtml.some(item => item.name === 'Camions');
      }
      
      //test(option)
/////////////////////

getSelectedMonth(value)
{
// this.selectedMonth =value
  const monthIndex = this.listMonthsannual.indexOf(value)+1;
  this.MonthSelectedforFrequenceAnual = monthIndex
  //this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

gemothsBiAnnual() {
  this.listMonthsannual_Bi_annual = [
    { name: 'Janvier', disabled: false },
    { name: 'Février', disabled: false },
    { name: 'Mars', disabled: false },
    { name: 'Avril', disabled: false },
    { name: 'Mai', disabled: false },
    { name: 'Juin', disabled: false },
    { name: 'Juillet', disabled: false },
    { name: 'Août', disabled: false },
    { name: 'Septembre', disabled: false },
    { name: 'Octobre', disabled: false },
    { name: 'Novembre', disabled: false },
    { name: 'Décembre', disabled: false },
  ];
  // if(this.benefit.frequence == "Bi-annuelle")
 
  //this.monthsChoised_frenquency_BiAnnuelle.pop()
 // this.monthsChoised_frenquency_BiAnnuelle =   []
  if (this.selectedMonthsBiAnnual.length > 2){
    this.monthsChoised_frenquency_BiAnnuelle=[]
    this.selectedMonthsBiAnnual =[]
    this.listMonthsannual_Bi_annual = [
      { name: 'Janvier', disabled: false },
      { name: 'Février', disabled: false },
      { name: 'Mars', disabled: false },
      { name: 'Avril', disabled: false },
      { name: 'Mai', disabled: false },
      { name: 'Juin', disabled: false },
      { name: 'Juillet', disabled: false },
      { name: 'Août', disabled: false },
      { name: 'Septembre', disabled: false },
      { name: 'Octobre', disabled: false },
      { name: 'Novembre', disabled: false },
      { name: 'Décembre', disabled: false },
    ];
  }  


  if (this.selectedMonthsBiAnnual.length >= 2) {
    const selectedMonthNames = this.selectedMonthsBiAnnual.map((month) => month['name']);

    this.listMonthsannual_Bi_annual = this.listMonthsannual_Bi_annual.map((month) => {
      if (!selectedMonthNames.includes(month.name)) {
        return { ...month, disabled: true };
      }
      return month;
    });
  }
}

getSelectedMonthBiAnnual(event: any) {
  this.selectedMonthsBiAnnual = event;
  let monthIndex1
  let monthIndex2
  let listMonths =[]
  if(this.selectedMonthsBiAnnual[0]){
  monthIndex1  = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[0]['name']);
  listMonths.push(monthIndex1+1)
  }
  if(this.selectedMonthsBiAnnual[1]){
   monthIndex2 = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[1]['name']);
   listMonths.push(monthIndex2+1)
  }
   this.MonthSelectedforFrequenceBi_annual = listMonths
}


  //Hebdomadaire 
  chooseDate(value){
    if(this.initialfrenquency != "Hebdomadaire"){
    let index = this.listDates.indexOf(value)
    if(index == -1){
    this.listDates.push(value)
    }
    else{
    this.listDates.splice(index,1)
    }
    const numbersList = this.listDates.map(day => this.dayToNumber[day]);
    this.hebdates=numbersList
    this.hebdates.sort(function(a, b) {
      return a - b;
  });
    if(this.hebdates.length > 0){
      this.validWeeklyBenefitForm = true
    }
  }
  else
  {
    this.listDates = this.convertToDays(this.benefit.listDates,this.dayToNumber)
    if(!this.listDates.includes(value)){
      this.listDates.push(value)
      var golocaleValue = this.dayToNumber[value];
      this.benefit.listDates.push(golocaleValue);
    }
    else{
      const index = this.listDates.indexOf(value);
      if (index !== -1) {
        // Remove "Sunday" from the array
        this.listDates.splice(index, 1);
        this.benefit.listDates.splice(index, 1);
      }
      //this.listDates = this.listDates.filter(day => day !== value);
    }
    const numbersList = this.listDates.map(day => this.dayToNumber[day]);
    this.hebdates=numbersList
    this.hebdates.sort(function(a, b) {
      return a - b;
  });
    if(this.hebdates.length > 0){
      this.validWeeklyBenefitForm = true
    }

    }
  }

  convertToDays(numbersArray, mapping) {
    const daysArray = [];
    numbersArray.forEach(number => {
      const day = Object.keys(mapping).find(key => mapping[key] === number);
      if (day) {
        daysArray.push(day);
      } else {
        daysArray.push("Invalid");
      }
    });
    return daysArray;
  }
  addType(){
    let modalRef
    modalRef =this.modalService.open(PopupAddTypebenefitGlassComponent);
  }

}
